var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { JanusApi, Configuration } from '../clients/NeurotecFaceVeritifactionManagementClient';
import { BasSessionClient } from '../clients/BasSessionClient';
import { ServerLogClient } from '../clients/ServerLogClient';
import { LogLevel } from '../models/enums/LogLevel';
import { ConnectionState } from '../models/enums/ConnectionState';
import { getScaleResolution, isLessThan, stringifyData } from '../domain/helpers/Common';
import { defaultFVOServiceConfiguration } from '../domain/defaultValues/DefaultFVOServiceConfiguration';
import { FatalError } from '../domain/errors/FatalError';
import { ErrorType } from '../models/enums/ErrorType';
import { CustomError } from '../domain/errors/CustomError';
import { CustomErrors } from '../models/enums/CustomErrors';
import { getVideoEnconding } from '../domain/helpers/FaceVerification';
export class FaceLivennessDetection {
    get hasAttampts() { return this.currentAttempt <= this.attemps; }
    get coef() { return this.attempsLoading[this.currentAttempt]; }
    // Mapeo los datos básicos del estado de la conexión webrtc
    get RTCStatus() {
        if (!this.rtcConnection)
            return null;
        const { connectionState, iceConnectionState, iceGatheringState, signalingState, localDescription, remoteDescription } = this.rtcConnection;
        // console.log(this.rtcConnection)
        return { connectionState, iceConnectionState, iceGatheringState, signalingState, localDescription, remoteDescription };
    }
    constructor(videoElement = document.querySelector(`video[face-liveness-video]`), configuration) {
        // Valores para los reintentos
        this.attemps = 3;
        this.currentAttempt = 1;
        // Valores para la barra de loading
        this.attempsLoading = [2 / 4, 3 / 4, 4 / 4];
        // Manejadores de eventos por defecto
        this.onstart = (ev) => { console.log('Start', ev); };
        this.onconnected = () => { console.log('OnConnected'); };
        this.onresult = (ev) => { console.log('Result', ev); };
        this.onfinish = (ev) => { console.log('Finish', ev); };
        this.onwarning = (ev) => { console.log('Warning', ev); };
        this.onerror = (ev) => { console.error('Error', ev); };
        this.onfatalerror = (ev) => { console.error('Fatal Error', ev); };
        this.onprocess = (ev) => { console.log('Process', ev); };
        this.onaction = (ev) => { console.log('Action', ev); };
        this.onactionlog = (ev) => { console.log('ActionLog', ev); };
        if (!videoElement)
            throw new Error("El Elemento Video no existe en el HTML");
        this.videoElement = videoElement;
        this.configuration = Object.assign(Object.assign({}, defaultFVOServiceConfiguration), configuration);
        this.log = this.configuration.log;
        this.debug = this.configuration.debug;
        // "Injecto" Servicios
        this.janusAPI = new JanusApi(new Configuration(this.configuration.fvoSdkParameters));
        this.basSessionClient = new BasSessionClient(this.configuration.basSessionApi);
        this.serverLogClient = new ServerLogClient(this.configuration.serverLogApi);
    }
    /**
     * Inicia un proceso de captura de rostro vivo.
     * @param transactionCode Código de transacción de captura de rostro.
     */
    startAsync(transactionCode) {
        this.onstart();
        // this.mediaStream = mediaStream;
        this.currentAttempt = 1;
        this.transactionCode = transactionCode;
        this.captureStamp = Date.now().toString();
        this.logfile = '';
        return this.startAttemptAsync();
    }
    /**
     * Realiza un intento de captura.
     */
    startAttemptAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            // Si hay reintentos aumento en 1 el intento actual
            if (this.hasAttampts) {
                this.showActionToDebugConsole(`Reintento ${this.currentAttempt}`, LogLevel.Information, null);
                this.currentAttempt += 1;
            }
            // Sino retorno vaci 
            else {
                return;
            }
            // Limpio datos del intento anterior
            yield this.stopCaptureAsync();
            // Comienzo a Streamear
            this.mediaStream = yield this.mediaStreamAsync();
            // Comienzo la captura
            try {
                // Obtengo parametros iniciales y creo session
                this.initParams = yield this.getInitParamsAsync();
                this.sessionData = yield this.createSessionAsync(this.transactionCode);
                this.onprocess((20 / 100) * this.coef);
                // Configuro WebRTC
                this.rtcConnection = this.setupWebRTC(this.initParams.iceServers, this.sessionData);
                this.dataChannel = this.setupDataChannel(this.rtcConnection, this.sessionData);
                this.onprocess((40 / 100) * this.coef);
                // Agrego Stream al Web RTC
                this.configureRTCrtpSender(this.mediaStream, this.rtcConnection);
                // Conecto con Janus
                yield this.connectToJanusAsync(this.transactionCode, this.rtcConnection);
                this.onprocess((70 / 100) * this.coef);
            }
            catch (error) {
                if (error.name === ErrorType.FATAL_ERROR) {
                    // Disparo error fatal y detengo la captura
                    this.onfatalerror(error);
                    this.stopCaptureAsync();
                }
                else {
                    //Reintento una nueva captura ante cualquier error de conexión
                    this.retryForError(error);
                }
            }
        });
    }
    /**
     * Realiza un reintento de captura, en caso de no tener intentos, dispara el error.
     * @param err Error del proceso
     */
    retryForError(error) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.hasAttampts) {
                this.startAttemptAsync();
            }
            else {
                yield this.stopCaptureAsync();
                this.onerror(error);
            }
        });
    }
    // #region RTC Connection
    closeSessionAsync(rtcConnection, session) {
        return __awaiter(this, void 0, void 0, function* () {
            rtcConnection.close();
            this.rtcConnection = null;
            return this.janusAPI.stopJanusConnection({ sessionData: session });
        });
    }
    setupWebRTC(iceServers, session) {
        const rtcConnection = new RTCPeerConnection({ iceServers });
        rtcConnection.onicecandidate = (event) => {
            const icecandidate = event.candidate;
            if (icecandidate) {
                this.janusAPI
                    .trickleJanus({ trickleData: { session, data: icecandidate } })
                    .catch(error => {
                    this.actionLog('/api/janus/trickle/', LogLevel.Warning, error);
                    this.onwarning(error);
                });
                this.actionLog('RTCConnection::onIceCandidate', LogLevel.Information, icecandidate.candidate);
            }
        };
        rtcConnection.onicecandidateerror = (error) => {
            this.actionLog('RTCConnection::onIceCandidateError', LogLevel.Error, {
                message: error.errorText,
                address: error.address,
                errorCode: error.errorCode,
                port: error.port,
                url: error.url
            });
            this.onwarning(new Error(error.errorText));
        };
        rtcConnection.onconnectionstatechange = (ev) => {
            if (rtcConnection.connectionState === ConnectionState.FAILED) {
                this.actionLog('RTCConnection::onConnectionStateChange', LogLevel.Error, Object.assign(Object.assign({}, this.RTCStatus), { message: 'Hubo un problema de conexión RTC.' }));
                this.retryForError(new Error('Hubo un problema de conexión'));
            }
            this.actionLog('RTCConnection::onConnectionStateChange', LogLevel.Information, this.RTCStatus);
        };
        // Eventos de la conexión Web RTC
        rtcConnection.ondatachannel = (ev) => this.showActionToDebugConsole('RTCConnection::onDataChannel', LogLevel.Information, this.RTCStatus);
        rtcConnection.oniceconnectionstatechange = (ev) => this.actionLog('RTCConnection::onIceConnectionStateChange', LogLevel.Information, this.RTCStatus);
        rtcConnection.onnegotiationneeded = (ev) => this.actionLog('RTCConnection::onNegotiationNeeded', LogLevel.Information, this.RTCStatus);
        rtcConnection.onsignalingstatechange = (ev) => this.actionLog('RTCConnection::onSignalingStateChange', LogLevel.Information, this.RTCStatus);
        rtcConnection.ontrack = (ev) => this.showActionToDebugConsole('RTCConnection::onTrack', LogLevel.Information, this.RTCStatus);
        rtcConnection.onicegatheringstatechange = (ev) => this.actionLog('RTCConnection::onIceGatheringStateChange', LogLevel.Information, this.RTCStatus);
        return rtcConnection;
    }
    setupDataChannel(rtcConnection, session) {
        const delay = this.configuration.dataReceptionTimeout;
        //const dataChannel = rtcConnection.createDataChannel('fvJanus', { maxPacketLifeTime: delay });
        const dataChannel = rtcConnection.createDataChannel('mmidJanus', { maxPacketLifeTime: delay });
        let data;
        let timeout;
        // Data Channel Error
        dataChannel.onerror = (event) => {
            const error = new Error(event.error.errorDetail);
            this.actionLog('RTCDataChannel::onError', LogLevel.Error, event);
            if (!data.operationFinished) {
                this.retryForError(error);
            }
        };
        // Data Channel Close
        dataChannel.onclose = () => {
            this.actionLog('RTCDataChannel::onClose', LogLevel.Information, 'El canal de datos está cerrado.');
        };
        // Data Channel Open
        dataChannel.onopen = () => {
            this.actionLog('RTCDataChannel::onOpen', LogLevel.Information, 'El canal de datos está abierto.');
            this.onconnected();
        };
        // Data Channel Message
        dataChannel.onmessage = (event) => {
            // Limpio el timeout
            timeout = clearTimeout(timeout);
            data = JSON.parse(event.data);
            if (data.operationFinished) // Finish Operation
             {
                if (this.mediaRecorder)
                    this.mediaRecorder.stop();
                this.closeSessionAsync(rtcConnection, session);
                this.onfinish(data);
                this.actionLog('OperationFinished', LogLevel.Information, data);
            }
            else // Streaming Operation Result
             {
                // Con estos handlers:
                // remarco de color el ovalo
                this.onresult(data);
                // muestro acciones al usuario              
                this.onaction(data.livenessAction);
                // this.showActionToDebugConsole('OperationResult', LogLevel.Information, data)
            }
        };
        return dataChannel;
    }
    /**
     * Agrego el MediaStream al PeerConnection que establezco con Janus e implemento
     * la configuracion de **rtpSender** para optimizar el video.
     * @param mediaStream Stream que envio a Janus.
     * @param rtcConnection PeerConnection que establezco con Janus.
     */
    configureRTCrtpSender(mediaStream, rtcConnection) {
        mediaStream.getTracks().forEach((track) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const enconding = getVideoEnconding(this.configuration.encoding);
            const sender = rtcConnection.addTrack(track, this.mediaStream);
            if (enconding) {
                const videoSettings = (_a = this.mediaStream.getVideoTracks()[0]) === null || _a === void 0 ? void 0 : _a.getSettings();
                // console.log(videoSettings.width, videoSettings.height, enconding.maxWidth, enconding.maxHeight)
                const ratioToReduce = getScaleResolution(videoSettings.width, videoSettings.height, enconding.maxWidth, enconding.maxHeight);
                // console.log(ratioToReduce);
                const parameters = sender.getParameters();
                if (!parameters.encodings)
                    parameters.encodings = [{}];
                parameters.encodings[0].scaleResolutionDownBy = ratioToReduce;
                parameters.encodings[0].maxBitrate = enconding.maxBitrate;
                parameters.encodings[0].maxFramerate = enconding.maxFramerate;
                parameters.encodings[0].priority = 'medium';
                sender.setParameters(parameters);
                this.showActionToDebugConsole('Encoding', LogLevel.Information, parameters.encodings[0]);
            }
        }));
    }
    /**
     * Establece conexión con Janus
     * @param transactionCode Código de transacción de captura de rostro
     * @param rtcConnection RTC Connection
     * @returns Promesa si se estableció conexion exitosamente
     */
    connectToJanusAsync(transactionCode, rtcConnection) {
        return __awaiter(this, void 0, void 0, function* () {
            return rtcConnection.createOffer()
                // Creo RTC Offer
                .then((rtcOffer) => __awaiter(this, void 0, void 0, function* () {
                yield rtcConnection.setLocalDescription(rtcOffer);
                this.actionLog('RTCConnection::createOffer', LogLevel.Information, rtcOffer);
                return rtcOffer;
            }))
                .catch(error => {
                this.actionLog('RTCConnection::createOffer', LogLevel.Error, error);
                throw error;
            })
                // Obtengo datos para establecer conexión con janus
                .then(rtcOffer => {
                return this.janusConnectAsync(transactionCode, rtcOffer);
            })
                // Conecto con Janus
                .then((janusResponse) => __awaiter(this, void 0, void 0, function* () {
                const jsep = janusResponse.jsep;
                yield rtcConnection.setRemoteDescription(new RTCSessionDescription(jsep));
                return janusResponse;
            }));
        });
    }
    /**
     * Parametros para iniciar las conexiones de captura de rostro
     * @returns Modelo de datos con los ICE Servers para establecer conexión
     */
    getInitParamsAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.basSessionClient.getInitParams()
                .then(initParams => {
                this.actionLog('/api/initParams/', LogLevel.Information, initParams);
                return initParams;
            })
                .catch(error => {
                this.actionLog('/api/initParams/', LogLevel.Error, error);
                throw error;
            });
        });
    }
    /**
     * Creo y obtengo parametros de sesión para establecer conexión con Janus
     * @param transactionCode Código de transacción de captura de rostro
     * @returns Datos de la sesión creada
     */
    createSessionAsync(transactionCode) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.basSessionClient.createSession(transactionCode)
                .then(sessionData => {
                this.actionLog('/api/create/', LogLevel.Information, sessionData);
                return sessionData;
            })
                .catch(error => {
                this.actionLog('/api/create/', LogLevel.Error, error);
                throw new FatalError(error.message);
            });
        });
    }
    /**
     * Obtengo modelo de datos para establecer conexión con Janus
     * @param transactionCode Código de transacción de captura de rostro
     * @param rtcOffer RTC Offer
     * @returns Modelo de Janus con los jsep para establecer conexión
     */
    janusConnectAsync(transactionCode, rtcOffer) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.basSessionClient.janusConnect(transactionCode, rtcOffer)
                .then(janusResponse => {
                const janusPluginData = janusResponse.plugindata;
                // Si la respuesta devuelve un error o no trae los jsep
                if ((janusPluginData && janusPluginData.data.error) || !janusResponse.jsep) {
                    // Disparo un error en el then para que continue por el catch
                    throw new Error(janusPluginData.data.error);
                }
                this.actionLog('/api/connect/', LogLevel.Information, janusResponse);
                return janusResponse;
            })
                .catch(err => {
                this.actionLog('/api/connect/', LogLevel.Error, err);
                throw err;
            });
        });
    }
    // #endregion    
    // #region Dev Methods
    /**
     * Logueo las acciónes del proceso de captura para crear reportes de funcionamiento
     * @param action
     * @param level
     * @param data
     */
    actionLog(action, level = LogLevel.Information, data) {
        this.logActionToServer(action, level, data);
        this.showActionToDebugConsole(action, level, data);
    }
    logActionToServer(action, level = LogLevel.Information, data) {
        if (this.log) {
            const payload = {
                code: this.transactionCode,
                name: action,
                logLevel: level,
                message: stringifyData(data),
                metadata: JSON.stringify({
                    attemp: this.currentAttempt - 1,
                    captureStamp: this.captureStamp,
                })
            };
            this.serverLogClient.send(payload);
        }
    }
    showActionToDebugConsole(action, level = LogLevel.Information, data) {
        if (this.debug) {
            switch (level) {
                case LogLevel.Information:
                    console.info(action, data);
                    break;
                case LogLevel.Warning:
                    console.warn(action, data);
                    break;
                case LogLevel.Error:
                    console.error(action, data);
                    break;
                default:
                    console.log(action, data);
            }
        }
        this.onactionlog({ action, level, data });
    }
    mediaStreamAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.mediaStream)
                return this.mediaStream;
            const options = {
                audio: false,
                video: Object.assign({ facingMode: 'user' }, this.configuration.videoQuality)
            };
            return yield navigator.mediaDevices.getUserMedia(options)
                .catch((err) => {
                throw new CustomError('La camara ya esta en uso', CustomErrors.CAMERA_NOT_FOUND);
            })
                .then(mediaStream => {
                if (this.hasBadResolution(mediaStream)) {
                    throw new CustomError('La camara tiene baja resolución', CustomErrors.BAD_CAMERA_RESOLUTION);
                }
                return this.videoElement.srcObject = mediaStream;
            })
                .catch(error => {
                this.onerror(error);
                throw error;
            });
        });
    }
    hasBadResolution(mediaStream) {
        // Obtener el primer track de video del stream
        const videoTrack = mediaStream.getVideoTracks()[0];
        // Obtener la configuración del track
        const settings = videoTrack.getSettings();
        return isLessThan(settings.width, settings.height);
    }
    cropVideoWithCanvas(videoElement) {
        return new Promise((resolve, reject) => {
            const canvas = document.getElementById("canvas-test") || document.createElement('canvas');
            const context = canvas.getContext('2d');
            // Esta funcion recorta cada frame del video y lo dibuja en el canvas.
            const draw = () => {
                if (videoElement.paused || videoElement.ended)
                    return;
                const videoWidth = videoElement.videoWidth; // Ancho del video original
                const videoHeight = videoElement.videoHeight; // Alto del video original
                const cropHeight = videoElement.videoHeight; // Alto del recorte                  
                const cropWidth = videoElement.videoHeight * (9 / 16); // Ancho del recorte
                // Establecer el tamaño del canvas al tamaño de recorte
                canvas.width = cropWidth;
                canvas.height = cropHeight;
                // Calcular la posición de recorte desde el centro del video
                const sourceX = (videoWidth - cropWidth) / 2;
                const sourceY = (videoHeight - cropHeight) / 2;
                // Dibujar el frame recortado en el canvas
                context.drawImage(videoElement, sourceX, sourceY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                requestAnimationFrame(draw);
            };
            videoElement.addEventListener('play', (event) => {
                draw();
                const newStream = canvas.captureStream(10);
                resolve(newStream);
            });
        });
    }
    // #endregion
    // #region Helpers Methods
    /**
     * Cierra la sesion WebRTC y limpia los valores que se guardan en un intento
     */
    stopCaptureAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.sessionData && this.rtcConnection) {
                yield this.closeSessionAsync(this.rtcConnection, this.sessionData);
            }
            this.initParams = null;
            this.sessionData = null;
            this.rtcConnection = null;
            this.dataChannel = null;
            this.janusResponse = null;
        });
    }
}
